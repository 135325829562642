var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"contactFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[(_vm.dialogConfig.existingWhatsappContacts.show)?_c('CrmExistingContactsView',{attrs:{"is-from-prospect-form":false,"contactNumberType":"de whatsapp","client":_vm.client},on:{"complete":function (event) {
        _vm.whatsappChanged = false;
        _vm.isWhatsAppValid = true;
        _vm.whatsappAcceptedDuplicate = _vm.whatsapp;
        _vm.toggleDialogExistingWhatsappContacts();
      },"cancel":function($event){return _vm.onCancelSave()}},model:{value:(_vm.existingContacts),callback:function ($$v) {_vm.existingContacts=$$v},expression:"existingContacts"}}):_vm._e(),(_vm.dialogConfig.existingContacts.show)?_c('CrmExistingContactsView',{attrs:{"is-from-prospect-form":false,"contactNumberType":"de telefone","client":_vm.client},on:{"complete":function (event) {
        _vm.isTelephoneValid = true;
        _vm.telephoneChanged = false;
        _vm.toggleDialogExistingContacts();
      },"cancel":function($event){return _vm.onCancelSave(false)}},model:{value:(_vm.existingContacts),callback:function ($$v) {_vm.existingContacts=$$v},expression:"existingContacts"}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dialogConfig.existingContacts.show && !_vm.dialogConfig.existingWhatsappContacts.show),expression:"!dialogConfig.existingContacts.show && !dialogConfig.existingWhatsappContacts.show"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._t("header",function(){return [(_vm.iconGeneral)?_c('v-icon',{attrs:{"left":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.iconGeneral)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.titleGeneral)+" ")]},{"title":_vm.titleGeneral,"icon":_vm.iconGeneral})],2),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.name'),"rules":_vm.rules.nome},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.nome),callback:function ($$v) {_vm.$set(_vm.model, "nome", $$v)},expression:"model.nome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.contactType'),"rules":_vm.rules.tiposContato,"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.contactTypeOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto","multiple":"","clearable":"","small-chips":""},on:{"change":function($event){return _vm.onChangeTipoContato($event)}},model:{value:(_vm.model.tiposContato),callback:function ($$v) {_vm.$set(_vm.model, "tiposContato", $$v)},expression:"model.tiposContato"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.email'),"rules":_vm.rules.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.telephone'),"rules":_vm.rules.telefone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMaskActive && _vm.telephoneMask),expression:"telephoneMaskActive && telephoneMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.telephoneMaskActive = true},"blur":function($event){return _vm.onVerifyContact()},"change":function($event){return _vm.onChangeTelephone()}},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.whatsapp'),"rules":_vm.rules.whatsapp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('vue-phone-number-input',{attrs:{"preferred-countries":_vm.preferredCountries,"show-code-on-list":true,"no-use-browser-locale":true,"fetch-country":false,"translations":_vm.translationsWhatsapp,"color":_vm.$vuetify.theme.currentTheme.primary,"clearable":true},on:{"update":function (e) { return _vm.onChangeWhatsApp(e); }},model:{value:(_vm.whatsapp),callback:function ($$v) {_vm.whatsapp=$$v},expression:"whatsapp"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.birthdate'),"rules":_vm.rules.dataAniversario},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('DatePickerField',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.dataAniversario),callback:function ($$v) {_vm.$set(_vm.model, "dataAniversario", $$v)},expression:"model.dataAniversario"}})]}}])})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('crm.contactForm.active'),"value":0,"hide-details":"auto"},model:{value:(_vm.model.flagAtivo),callback:function ($$v) {_vm.$set(_vm.model, "flagAtivo", $$v)},expression:"model.flagAtivo"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._t("header",function(){return [(_vm.iconPreference)?_c('v-icon',{attrs:{"left":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.iconPreference)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.titlePreference)+" ")]},{"title":_vm.titlePreference,"icon":_vm.iconPreference})],2),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('crm.contactForm.bestTime')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.bestStartTime')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
return [_c('TimePickerField',{attrs:{"hide-details":"auto","label":label},model:{value:(_vm.model.melhorHoraInicio),callback:function ($$v) {_vm.$set(_vm.model, "melhorHoraInicio", $$v)},expression:"model.melhorHoraInicio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.bestEndTime')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
return [_c('TimePickerField',{attrs:{"hide-details":"auto","label":label},model:{value:(_vm.model.melhorHoraFim),callback:function ($$v) {_vm.$set(_vm.model, "melhorHoraFim", $$v)},expression:"model.melhorHoraFim"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.contactChannel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
return [_c('v-autocomplete',{attrs:{"items":_vm.channelOptions,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto","multiple":"","clearable":"","small-chips":""},model:{value:(_vm.model.canaisContato),callback:function ($$v) {_vm.$set(_vm.model, "canaisContato", $$v)},expression:"model.canaisContato"}})]}}])})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.saveButtonText))])])],1),(_vm.showSync)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"lightgrey","disabled":_vm.disableSync},on:{"click":_vm.onSync}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.syncToErp')))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1),_c('v-col',{staticClass:"espacamento"},[_c('v-spacer')],1),(_vm.enableDelete && _vm.id != null)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"text":""},on:{"click":_vm.onDeleteContact}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t('crm.contactForm.btnDeleteContact')))])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }